// 服务协议
<template>
  <div class="index">
    <div>
      <!-- banner -->
      <el-carousel height="450px" class="indexBanner">
        <el-carousel-item v-for="(item, index) in this.$store.getters.banner" :key="index">
          <img :src="item.pic" alt="" style="width: 100%; height: 100%" />
        </el-carousel-item>
      </el-carousel>
      <!-- 公告 -->
      <el-carousel height="50px" direction="vertical" class="indexNotice">
        <el-carousel-item v-for="(item, index) in $store.getters.rollList" :key="index">
          <h3 class="medium">
            <span>网站公告：</span> <span>{{ item.title }}</span>
            <span>{{ item.createTime }}</span>
            <span @click="handleCommandAS" style=" cursor: pointer;">更多<i class="el-icon-arrow-right"></i></span>
          </h3>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="container">
     <div class="title" style="line-height:60px;border-bottom:1px dashed blue;margin-bottom:15px;font-size:16px">
        服务协议
      </div>
      <el-collapse accordion>
        <el-collapse-item v-for="item in brandlist" :key="item.id">
          <template slot="title">
            {{item.title}}
          </template>
          <div  v-html="item.content"></div>
        </el-collapse-item>
      </el-collapse>
      <el-pagination style="text-align:center;margin: 20px auto;"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNo"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    </div>
  </div>
</template>
<script>
import {apiGetArticleType,apiGetArticleByType} from '@/api/arcticle'
export default {
  name: "brand",
  data() {
    return {
      brandlist: [],
      pageSize: 10,
      total: 0,
      pageNo: 1
    };
  },
  mounted(){
    this.getArticleList()
  },
  methods:{
    handleCommandAS(){
      this.$router.push({name: 'joiningNotice'})
      this.$store.commit('SET_INFO',['menuIndex','joining'])
    },
    getTypeList(){
      apiGetArticleType().then(res=>{
        console.log(res);
      })
    },
    getArticleList(){
      apiGetArticleByType({cid: 593,page: this.pageNo,limit: this.pageSize}).then(res=>{
        console.log(res);
          if(res.code == 200){
          this.brandlist = res.data.list
          this.total =  res.data.total
        }
      })
    },
    handleSizeChange(val){
      this.pageSize = val
      this.pageNo =1
      this.getArticleList()
    },
     handleCurrentChange(val){
      this.pageNo =val
      this.getArticleList()
    },
  }
};
</script>
<style lang="scss" scoped>
.index {
  width: 1200px;
  margin: 0 auto;
  background-color: #f5f6f7;
}
.indexBanner,
.indexNotice,
.container {
  background-color: #fff;
  margin-bottom: 10px;
}
.breadcrumb {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  height: 50px;
  padding: 0 20px;
  align-items: center;
  border-bottom: 3px solid#f1f2f3;
}
.medium {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 80px;
}
::v-deep.container{
  padding: 20px 30px;
  text-align: left;
  .el-collapse {
    border-top: 1px dashed #ebeef5;
    border-bottom: 1px dashed #ebeef5;
  }
  .el-collapse-item__header{
    line-height: 60px;
    height: 60px;
    border-bottom: 1px dashed #ebeef5;
  }
  .el-collapse-item__header.is-active{
    border-color: transparent!important;
  }
  .el-collapse-item__content{
    background-color: #f7f8f8;
    line-height: 26px;
    padding: 10px;
  }
}
</style>